<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
@import "https://fonts.googleapis.com/css2?family=Assistant:wght@400;800&display=swap";

:root {
  --green: #49B864;
  --red: #FF3057;
  --yellow: #E5D602;
  --blue: #007bff;
  --orange: #F78937;
  --black: #111111;
  --bs-modal-padding: 20px;
}


body, html {
  font-size: 16px;
  overflow: hidden;
  height: 100%;
  font-family: 'Assistant', sans-serif !important;
}

body {
  direction: ltr;
  color: white;
  background-color: #111111;
}

#app, main {
  height: 100%;
  background: #111111 center/cover no-repeat;
}

main {
  h2 {
    color: white;
    font-size: 3rem;
    padding-left: 5px;
    padding-right: 5px;
  }

  h1, h2, h3, h4, h5 {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    user-select: none; /* Standard */
  }

  h1 {
    color: whitesmoke;
  }

  h3 {
    font-size: 1.75rem;
    color: #e3e3e3;
  }
}

.flex-apart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>
