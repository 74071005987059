<template>
  <div class="q-modal">
    <div class="q-modal-content">
      <div class="q-modal-heading">
        <h2 :style="{direction:lang==='he'?'rtl':'ltr'}">{{ title }}</h2>
      </div>
      <div v-if="played">
        <h3 :style="{direction:lang==='he'?'rtl':'ltr'}">
          {{
            lang === 'he' ? 'נראה שכבר שיחקתם באתגר הזה, נתראה במשחק הבא!' : 'Looks like you have already played this game, see you next time!'
          }}</h3>
      </div>
      <div v-else :style="{direction:lang==='he'?'rtl':'ltr'}">
        <h3>{{ lang === 'he' ? 'קוד אימות' : 'PIN Code' }}</h3>
        <input type="number" v-model="pin" minlength="6" min="100000" max="999999" @input="clearWrongPin"
               :placeholder="lang==='he'?'קוד':'PIN'" maxlength="6"/>
        <h4 v-if="!isPinWrong" :style="{direction:lang==='he'?'rtl':'ltr'}">
          {{
            lang === 'he' ? (' השתמשו בקוד שנשלח אליכם ב-' + methodDisplay + ' או בקשו אחד חדש') : ("Please check your " + methodDisplay + " for verification PIN")
          }}
        </h4>
        <h4 v-if="isPinWrong" :style="{direction:lang==='he'?'rtl':'ltr'}">
          {{
            lang === 'he' ? 'הקוד שהקשתם שגוי, נסו שוב או בקשו קוד חדש.' : "Your PIN is wrong, please try again or request a new one."
          }}
        </h4>
        <button @click="checkPin" :class="{disabled: (pin.toString().length!==6 || isPinWrong )}">
          {{ lang === 'he' ? 'אמת' : 'Verify' }}
        </button>
        <button @click="newPin" :class="{disabled: isPinLocked}">{{ lang === 'he' ? 'שלחו קוד חדש' : 'Get new PIN' }}
        </button>
        <p v-show="isPinLocked"> {{
            lang === 'he' ? ('הקוד נשלח ב-' + methodDisplay + ', בדקו בבקשה גם בספאם.') : ('PIN sent via ' + methodDisplay + '. Please check also spam folder.')
          }}</p>
      </div>

    </div>
    <div class="q-modal-overlay" @click="close"></div>
  </div>
</template>

<script>
export default {
  name: "ModalVerify",
  props: ['code', 'lang', 'isQuiz', 'title', 'isPinLocked', 'isPinWrong', 'played', 'method'],
  data() {
    return {
      pin: '',
      checking: false,
    }
  },
  mounted() {
    this.checking = false;
  },
  methods: {
    checkPin() {
      if (!this.checking) {
        this.$emit('checkPin', this.pin);
        this.checking = true;
        setTimeout(() => this.checking = false, 2000);
      }
    },
    clearWrongPin() {
      this.$emit('clearWrongPin');
    },
    newPin() {
      this.$emit('newPin');
    },
    close() {
      this.$parent.showVerifyModal = false;
    }
  },
  computed: {
    methodDisplay() {
      if (this.method === 'email') {
        return this.lang === 'he' ? 'אימייל' : 'Email';
      } else if (this.method === 'tel') {
        return 'SMS';
      }
      return '';
    }
  }
}
</script>

<style scoped lang="scss">

.q-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  h4 {
    color: #000000;
    max-width: 250px;
    margin: 10px auto;
    line-height: 20px;
    font-size: 18px;
  }

  p {
    color: #000000;
    max-width: 250px;
    margin: 5px auto;
    line-height: 18px;
    font-size: 16px;
  }

  .q-modal-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    z-index: 0;
  }

  .q-modal-content {
    position: absolute;
    width: 100%;
    max-width: 480px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    margin: auto;
    z-index: 1001;
    background: #ffffff;
    text-align: center;
    padding-bottom: 40px;
    border-bottom: 10px solid black;

    .q-modal-heading {
      background: #000000;
    }

    h2 {
      font-size: 32px;
      line-height: 50px;
    }

    h3 {
      color: #2E2F2B;
      margin: 20px;
    }

    input {
      width: 250px;
      font-size: 42px;
      border-color: #707070;
      outline: none;
      text-transform: uppercase;
      text-align: center;
      border-radius: 2px;
    }

    button {
      background: var(--green);
      color: white;
      border: none;
      border-radius: 2px;
      width: 250px;
      font-size: 28px;
      padding: 10px 40px;
      margin-bottom: 20px;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }


}

</style>
