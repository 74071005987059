import SimpleDialog from './../components/SimpleDialog'
import {createConfirmDialog} from 'vuejs-confirm-dialog'

const useConfirmBeforeAction = (props, action, cancel) => {
    const {reveal, onConfirm, onCancel} = createConfirmDialog(SimpleDialog, props)
    onConfirm(action)
    if (typeof cancel !== 'undefined') onCancel(cancel)
    reveal()
}

export default useConfirmBeforeAction
