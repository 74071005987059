<template>
  <div class="q-modal">
    <div class="q-modal-content">
      <div class="q-modal-heading">
        <h2>{{ title }}</h2>
      </div>
      <h3>Game ID</h3>
      <input type="text" @change="checkCode" v-model="newCode" minlength="4"
             :placeholder="lang==='he'?'מזהה':'id'" maxlength="4"/>
      <h4 id="insertCodeLabel" :style="{direction:lang==='he'?'rtl':'ltr'}">
        <font-awesome-icon
          icon="check" v-show="isQuiz"></font-awesome-icon>&nbsp;<span>{{
          isQuiz ? (lang === 'he' ? 'המשחק זמין' : 'Game is available') : (lang === 'he' ? 'הקוד יופיע בפתיח של המשחק' : 'Ask for the unique code')
        }}</span>
      </h4>
      <button @click="checkCode">Update</button>
    </div>
    <div class="q-modal-overlay" @click="close"></div>
  </div>
</template>

<script>
export default {
  name: "ModalCode",
  props: ['code', 'lang', 'isQuiz', 'title'],
  data() {
    return {
      newCode: ''
    }
  },
  mounted() {
    console.log(this.code)
    this.newCode = this.code;
  },
  methods: {
    checkCode() {
      this.$emit('updateCode', this.newCode);
      this.close();
    },

    close() {
      this.$parent.showCodeModal = false;
    }
  }
}
</script>

<style scoped lang="scss">

.q-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  .q-modal-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    z-index: 0;
  }

  .q-modal-content {
    position: absolute;
    width: 100%;
    max-width: 480px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    margin: auto;
    z-index: 1001;
    background: #ffffff;
    text-align: center;
    padding-bottom: 40px;
    border-bottom: 10px solid black;

    .q-modal-heading {
      background: #000000;
    }

    h2 {
      font-size: 32px;
      line-height: 50px;
    }

    h3 {
      color: #2E2F2B;
      margin: 20px;
    }

    input {
      width: 250px;
      font-size: 42px;
      border-color: #707070;
      outline: none;
      text-transform: uppercase;
      text-align: center;
      border-radius: 2px;
    }

    button {
      background: var(--green);
      color: white;
      border: none;
      border-radius: 2px;
      width: 250px;
      font-size: 32px;
      padding: 10px 40px;
    }
  }


}

</style>
