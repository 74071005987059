<template>
  <div class="buzz-answer">
    <div class="answer-box" :class="{'lost-time': correct === 1}">
      <div :key="'ans-'+i" v-for="(a, i) in answerArr">
        <label v-if="a.length - (buzzAnswer[i] ? buzzAnswer[i].length : 0)">{{
            a.length - (buzzAnswer[i] ? buzzAnswer[i].length : 0)
          }}</label>
        <input :style="{direction:dir, width: (1.22*a.length + 0.5)+'ch'}" v-model="buzzAnswer[i]"
               :maxlength="a.length">&nbsp;
      </div>
    </div>
    <em class="buzz-answer-result" :class="{'buzz-correct': correct >= 1, 'buzz-wrong': correct === 0  }"/>
    <button :disabled="disabled" class="btn btn-success btn-block" @click="sendBuzzAnswer">
      {{ dir === 'rtl' ? 'שלח תשובה' : 'Submit answer' }}
    </button>
  </div>
</template>

<script>
export default {
  name: "PinCode",
  props: ['answer', 'dir', 'correct', 'disabled'],
  data() {
    return {
      buzzAnswer: [],
    }
  },
  methods: {
    sendBuzzAnswer() {
      this.$emit('answerSubmit', this.buzzAnswer.join(" "))
    }
  },
  computed: {
    answerArr() {
      return this.answer.split(" ")
    }
  }
}
</script>

<style scoped lang="scss">

.buzz-answer {
  padding: 10px;
  position: absolute;
  overflow: hidden auto;
  max-height: 100%;
  text-align: center;
  width: 100%;

  .buzz-answer-result {
    bottom: 25px;
    right: 10px;
    width: 40px;
    height: 40px;
    z-index: 99;
    pointer-events: none;
    position: absolute;


    &.buzz-correct {
      background: transparent center/contain url("@/assets/img/correct.png") no-repeat;
    }

    &.buzz-wrong {
      background: transparent center/contain url("@/assets/img/wrong.png") no-repeat;
    }
  }

  .answer-box {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    letter-spacing: normal;
    flex-wrap: wrap;

    > div {
      position: relative;
      margin: 10px;
    }

    label {
      position: absolute;
      right: -5px;
      top: -5px;
      pointer-events: none;
      color: white;
    }

    input {
      display: inline-block;
      background: transparent;
      border: none;
      border-bottom: 1px solid white;
      color: #fff;
      font-size: 3.8ch;
      line-height: 1.1;
      letter-spacing: 0.3ch;
      font-family: 'Cousine', monospace;
      box-sizing: content-box;
      padding-left: 0.5ch;

      &::-webkit-search-decoration {
        -webkit-appearance: none;
      }

      &:focus {
        outline: none;
        color: #fff;
      }

    }

  }

  button {
    display: block;
  }

  .lost-time {
    opacity: 0.5;
  }
}
</style>
