import { createRouter, createWebHistory } from 'vue-router'
import RemoteView from '../views/RemoteView.vue'
import ResultsView from '../views/ResultsView.vue'

const routes = [
  {
    path: '/:urlCode', name: 'RemoteWithCode', props: true, component: RemoteView
  }, {
    path: '/', name: 'RemoteView', component: RemoteView
  }, {
    path: '/results/:urlCode', name: 'Results', props: true, component: ResultsView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
