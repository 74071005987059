import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import * as ConfirmDialog from 'vuejs-confirm-dialog'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/animate.css'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faSync,
    faCheck,
    faLock,
    faGamepad,
    faUser,
    faUsers,
    faMedal,
    faTrophy,
    faPlay,
    faLink,
    faPaperPlane,
    faTimes,
    faBars,
    faInfo
} from '@fortawesome/free-solid-svg-icons'

library.add(faSync, faCheck, faLock, faGamepad, faUser, faUsers, faMedal, faTrophy, faPlay, faLink, faPaperPlane, faTimes, faBars, faInfo)

const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router);
app.use(ConfirmDialog);
app.mount('#app');
